<template>
  <div class="dad">
    <div class="bg-dark rounded mb-2 d-flex w-100 flow-iphone">
      <div
        class="
          bg-secondary
          d-flex
          justify-content-center
          align-items-center
          rounded
          back-btn
        "
        style="width: 5rem"
        @click="goBack()"
      >
        ⬅️
      </div>
      <div class="flex-grow-1 text-center py-3">
        <h1 class="display-4">Chatti</h1>
      </div>
    </div>

    <div class="container">
      <p>
        Meillä on
        <span class="font-weight-bold text-info">vuohi x fuckhsl</span> discord
        serveri, johon kannattaa liittyä.<br /><br />Sieltä saa uudet linkit,
        uutiset ja muuta hyödyllistä tietoa. Siellä voi myös kysyä kysymyksiä ja
        jättää palautetta.
      </p>
      <a class="btn btn-primary" href="https://discord.gg/Ba4sg5sSKa"
        >Liity tästä 😈</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Chat",
  data: () => ({}),
  methods: {
    goBack() {
      this.$router.push({ name: "Settings" });
    },
  },
};
</script>

<style lang="scss" scoped>
.dad {
  height: 100vh;
  width: 100%;

  .flow-iphone {
    padding-top: env(safe-area-inset-top);
    box-sizing: content-box;
  }
}
</style>
